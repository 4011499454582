<template>
  <div class="price-calendar-page">

    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong> {{$t('priceCalendarEditPage.pageTitle')}}</strong>
          </CCardHeader>

          <CCardBody>
            <div class="calender-price-list">
              <CAlert
                color="info"
                class="text-center py-1 px-0 mt-2 w-25 mx-auto"
              ><b>{{currentMont}}</b></CAlert>
              <CRow class="mb-2">

                <!-- #region 前の4カ月 -->
                <CCol>
                  <CButton
                    class="btn-light"
                    @click="showMonthNav(-4)"
                  >
                    <i class="icon cil-chevron-double-left"></i>
                    {{$t('planPriceEditPage.prev4months')}}
                  </CButton>
                </CCol>
                <!-- #endregion 前の4カ月 -->

                <!-- #region 当月 -->
                <CCol>

                  <CButton
                    class="btn-light mx-auto d-block"
                    @click="showCurrentMonthNav()"
                  >
                    {{$t('planPriceEditPage.currentMonth')}}
                  </CButton>

                </CCol>
                <!-- #endregion 当月 -->

                <!-- #region 次の4カ月 -->
                <CCol>
                  <CButton
                    class="btn-light float-right"
                    @click="showMonthNav(4)"
                  >
                    {{$t('planPriceEditPage.next4months')}} <i class="icon cil-chevron-double-right"></i>
                  </CButton>
                </CCol>
                <!-- #endregion 次の4カ月 -->

                <!-- #region 基準料金設定 -->
                <!-- #endregion 基準料金設定 -->

              </CRow>
              <ul class="calendar-list">
                <li
                  v-for="month in 4"
                  :key="month"
                >
                  <Calendar
                    class="custom-calendar max-w-full m-1 border-0"
                    :locale="$i18n.locale"
                    :attributes="attributes"
                    :min-date="new Date()"
                    :is-expanded="$screens({ default: true, lg: false })"
                    :ref="'calendar'+month"
                  >

                    <template v-slot:day-content="{ day }">

                      <div class="flex flex-col h-full z-10 overflow-hidden h-100">
                        <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                        <div class="flex-grow overflow-y-auto overflow-x-auto">
                          <span class="custom-event">
                            <template v-for="attr in day.attributes">
                              <div :key="attr.customData.description">
                                <span
                                  class="custom-date"
                                  v-if="attr.customData.description"
                                >
                                  {{ attr.customData.description | wordLimit(5, '...')  }}
                                </span>
                              </div>
                            </template>
                          </span>
                          <CButton
                            size="sm"
                            block
                            class="p-0"
                            color="dark"
                            @click="openDateEvent(day) "
                            :disabled="possibleDay(day)"
                          >編集</CButton>

                        </div>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="event-list">
                        <template v-for="date in attributes">
                          <li
                            :key="date.customData.description"
                            class="text-danger"
                            v-if="onDayEvent(month, date.dates)"
                          >
                            ● {{date.customData.description}}
                          </li>
                        </template>
                      </ul>
                    </template>
                  </Calendar>
                </li>
              </ul>
              <!-- create new Event modal  -->
              <CModal
                size="lg"
                color="info"
                :closeOnBackdrop="false"
                :centered="true"
                :show.sync="addEventModal"
              >
                <template #header>{{$t('priceCalendarEditPage.dateCreate')}}</template>
                <div class="submit_form">

                  <!-- #region date -->
                  <CRow>
                    <CCol class="label"><strong>{{$t('priceCalendarEditPage.date')}}</strong></CCol>
                    <CCol>
                      {{selectedDate | formatDate}}
                    </CCol>
                  </CRow>
                  <!-- #endregion date -->
                  <!-- #region 設定期間 -->
                  <CRow>
                    <CCol class="label"><strong>{{$t('priceCalendarEditPage.dateName')}}</strong></CCol>
                    <CCol>
                      <CInput v-model="newAttr.description" />
                    </CCol>
                  </CRow>
                  <!-- #endregion 設定期間 -->
                </div>
                <template #footer>
                  <CButton
                    @click="addEventModal=false"
                    color="secondary"
                  >
                    <i class="icon cil-x"></i> {{$t('common.cancel')}}
                  </CButton>

                  <CButton
                    color="info"
                    @click="createDayEvent()"
                  >
                    <i class="icon cil-save"></i> {{$t('common.save')}}
                  </CButton>
                </template>
              </CModal>

              <!-- edit event  -->
              <CModal
                size="lg"
                color="info"
                :closeOnBackdrop="false"
                :centered="true"
                :show.sync="editEventModal"
              >
                <template #header>{{$t('priceCalendarEditPage.dateEdit')}}</template>
                <div class="submit_form">

                  <!-- #region date -->
                  <CRow>
                    <CCol class="label"><strong>{{$t('priceCalendarEditPage.date')}}</strong></CCol>
                    <CCol>
                      {{editAttr.date | formatDate}}
                    </CCol>
                  </CRow>
                  <!-- #endregion date -->
                  <!-- #region 設定期間 -->
                  <CRow>
                    <CCol class="label"><strong>{{$t('priceCalendarEditPage.dateName')}}</strong></CCol>
                    <CCol>
                      <CInput v-model="editAttr.description" />
                    </CCol>
                  </CRow>
                  <!-- #endregion 設定期間 -->
                </div>
                <template #footer>
                  <CButton
                    @click="editEventModal =false"
                    color="secondary"
                  >
                    <i class="icon cil-x"></i> {{$t('common.cancel')}}
                  </CButton>
                  <CButton
                    @click="deleteEvent()"
                    color="danger"
                  >
                    <i class="icon cil-trash"></i> {{$t('common.delete')}}
                  </CButton>
                  <CButton
                    color="info"
                    @click="editDayEvent()"
                  >
                    <i class="icon cil-save"></i> {{$t('common.save')}}
                  </CButton>
                </template>
              </CModal>

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4"
                color="success"
                @click="onUpdateClicked()"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";
import Moment from "moment";

export default {
  data() {
    return {
      //#region Flag

      bulkChargeSettingModal: false,
      bulkDiscountSettingModal: false,
      eventModal: false,
      currentMont: "",
      //#endregion Flag

      successModal: false,
      errorModal: false,
      //#endregion Flag

      //#region Message
      errorMessage: "error",

      addEventModal: false,
      editEventModal: false,

      days: [],
      selectedDate: "",
      singleDate: "",
      SelectedPriceType: {},
      attributes: [],
      editAttr: {
        date: "",
        description: "",
      },
      newAttr: {
        description: "",
      },
      test: "",

      edit: {},

      //#region 一括料金設定モーダル用
      mode: "基準料金",

      //#endregion 一括料金設定モーダル用

      reload: 1,
      testAttrData: {},
      customTest: [],
    };
  },

  beforeMount() {
    this.getCatendarData();
  },
  mounted() {
    this.show4month();
  },
  computed: {},
  methods: {
    /** get all date data of calendar */
    getCatendarData() {
      var urlRequest = "/rest/calendar";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // console.log("calendar data:" + JSON.stringify(resp.data));
          var dateData = resp.data.items;
          // var sortData = getDateData.sort((a, b) => b.date - a.date);
          dateData.sort(
            (a, b) =>
              new Moment(a.date).format("YYYYMMDD") -
              new Moment(b.date).format("YYYYMMDD")
          );

          dateData.forEach((e, i) => {
            this.attributes.push({
              key: i,
              customData: {
                description: e.description,
                id: e.id,
                country: e.country,
                type: e.type,
                date: e.date,
              },
              dates: new Date(e.date),
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.categoryImageList = [];
        });
    },
    /**
     * Month navigation
     */
    showMonthNav(step) {
      const firstMonth = this.$refs;
      const monthNav = [];

      for (const prop in firstMonth) {
        firstMonth[prop][0].move(step);
        monthNav.push(firstMonth[prop][0].firstPage.title);
      }
      this.currentMont = monthNav[0] + " ~ " + monthNav[monthNav.length - 1];
    },

    /**
     * show four month on mount
     * */
    show4month() {
      const firstMonth = this.$refs;
      const monthNav = [];
      var n = 0;
      for (const prop in firstMonth) {
        firstMonth[prop][0].move(n);
        monthNav.push(firstMonth[prop][0].firstPage.title);
        n++;
      }
      this.currentMont = monthNav[0] + " ~ " + monthNav[monthNav.length - 1];
    },

    /**
     * show current displayable month
     * */
    showCurrentMonthNav() {
      const firstMonth = this.$refs;
      const monthNav = [];
      var n = 1;
      for (const prop in firstMonth) {
        // console.log(n);
        var d = new Date();
        firstMonth[prop][0].move({
          month: d.getMonth() + n,
          year: d.getFullYear(),
        });
        monthNav.push(firstMonth[prop][0].firstPage.title);
        n++;
      }
      this.currentMont = monthNav[0] + " ~ " + monthNav[monthNav.length - 1];
    },

    /**
     * check day events
     * */
    onDayEvent(cal, dates) {
      var kd = cal - 1;
      // console.log("date" + dates.getMonth());
      const firstMonth = this.$refs;
      const monthNav = [];
      const yearNav = [];
      for (const prop in firstMonth) {
        monthNav.push(firstMonth[prop][0].firstPage.month);
        yearNav.push(firstMonth[prop][0].firstPage.year);
      }
      var tDate = dates.getMonth() + 1;

      var returnBool = false;
      monthNav[kd] == tDate && yearNav[kd] == dates.getFullYear()
        ? (returnBool = true)
        : (returnBool = false);
      return returnBool;
    },

    /**
     * Button: open create event date
     */
    openDateEvent(attr) {
      // console.log("Attr" + JSON.stringify(attr.attributes[0].customData));
      console.log(attr.date);

      attr.attributes[0]
        ? ((this.editEventModal = true),
          (this.editAttr = attr.attributes[0].customData))
        : ((this.addEventModal = true), (this.selectedDate = attr.date));
      // this.selectedDate = attr.date;
    },

    /** Button: submit new day event */
    createDayEvent() {
      var DId = new Date(this.selectedDate).toJSON().slice(0, 10).split`-`
        .join``;
      var newData = [];

      newData.push({
        id: DId,
        country: "jpn",
        description: this.newAttr.description,
        type: 10,
        date: this.selectedDate,
      });
      console.log("new sub" + JSON.stringify(newData));
      const urlRequest = "/rest/calendar/";
      axios
        .post(urlRequest, newData, {
          headers: { "Accept-Language": "*" },
        })
        .then((resp) => {
          console.log(resp);
          this.attributes = [];
          this.getCatendarData();
          this.addEventModal = false;
          this.newAttr = [];
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },

    /** edit day event */
    editDayEvent() {
      this.editAttr.country = "jpn";
      // this.editAttr.dates = this.selectedDate;

      console.log(JSON.stringify(this.editAttr));
      // var sDate = new Date(this.selectedDate).toISOString().slice(0, 10);
      // console.log(sDate);
      const urlRequest = `/rest/calendar/${this.editAttr.date}`;
      axios
        .put(urlRequest, [this.editAttr], {
          headers: { "Accept-Language": "*" },
        })
        .then((resp) => {
          console.log(resp);
          this.attributes = [];
          this.getCatendarData();
          this.editEventModal = false;
          this.editAttr = [];
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },
    deleteEvent() {
      // var sDate = new Date(this.selectedDate).toISOString().slice(0, 10);
      // console.log(sDate);
      const urlRequest = `/rest/calendar/${this.editAttr.date}`;
      axios
        .delete(urlRequest, {
          headers: { "Accept-Language": "*" },
        })
        .then((resp) => {
          this.attributes = [];
          console.log(resp);
          this.getCatendarData();
          this.editEventModal = false;
          this.editAttr = [];
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },

    /**
     * cancel event
     *
     */
    cancelEvent() {
      this.eventModal = false;
      // this.customData.description = "";
    },

    /**
     * check if the date is previous
     *
     */
    possibleDay(day) {
      return new Date(day.date).valueOf() < new Date().valueOf();
    },
  },
};
</script>


<style lang="scss">
#child-component-item:after {
  background-color: var(--bgColor);
}
.calender-price-list {
  .vc-pane {
    margin: 0.5rem;
    border: 1px solid #cccccc;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    // --day-width: 90px;
    // --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;
    & .vc-header {
      background-color: #f1f5f8;
      padding: 10px 0;
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background-color: var(--weekday-bg);
      border-bottom: var(--weekday-border);
      border-top: var(--weekday-border);
      padding: 5px 0;
    }
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      background-color: white;
      &.weekday-1,
      &.weekday-7 {
        background-color: #eff8ff;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    }
    .custom-event {
      display: inline-table;
      padding: 0;
      margin-bottom: 2px;
      width: 100%;
      .custom-date {
        text-align: center;
        display: block;
        background: #f7dfdf;
        border: 1px solid #f8aeae;
        font-size: 0.9em;
        line-height: 1.5em;
        border-radius: 3px;
      }
    }
  }
  .calendar-list {
    width: 100%;
    li {
      display: inline-block;
      width: 49%;
      &:nth-child(odd) {
        margin-right: 1%;
      }
    }
  }
  .is-left,
  .is-right {
    display: none;
  }
  .event-list {
    clear: both;
    margin: 0 1%;
    // padding: 1%;
    border-radius: 4px;
    background-color: #fadddd;
    border-color: #f8cfcf;
    li {
      display: block;
      width: 100%;
      margin: 0;
      padding-left: 1%;
      background-color: #fadddd;
      border-color: #f8cfcf;
    }
  }
}
.pre-view {
  height: 80vh;
  overflow: auto;
}
</style>